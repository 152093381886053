import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MainTitle, StaticTitle, Text, LinkBack } from "../elements/Text"
import { size } from "../elements/font"

const MainContainer = styled.main`
  padding: 20vh 2vw 0 2vw;
  overflow: hidden;
  background: #ffffff;
  @media only screen and (max-width: 900px) {
    padding-top: 10vh;
  }
`

const Title = styled(MainTitle)`
  text-align: left;
  margin-bottom: 0;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:first-of-type) > * {
    border-bottom: 1px solid #c9c9c9;
    margin: 0 5px;
  }
  & > :nth-child(1) {
    width: 20%;
  }
  & > :nth-child(2) {
    width: 15%;
  }
  & > :nth-child(3) {
    flex: 1;
  }
  @media only screen and (max-width: 900px) {
    width: 400%;
  }
`

const TableTitle = styled(StaticTitle)`
  text-align: center;
  margin-top: 5vh;
  font-size: ${size.S};
`

const Category = styled(Text)`
  font-weight: bold;
  letter-spacing: 0.05em;
`

const MobileContainer = styled.div`
  width: 100%;
  @media only screen and (max-width: 900px) {
    height: 60vh;
    overflow: scroll;
  }
`

const ButtonContainer = styled.div`
  position: fixed;
  right: calc(5vw + 250px);
  top: 44px;
  z-index: 2000;
  & > a {
    padding: 14px 30px;
  }
  @media only screen and (max-width: 900px) {
    right: 5vw;
    top: 100px;
    & > a {
      padding: 10px 26px;
    }
  }
`

const ToolsMainContainer = styled(MainContainer)`
  @media only screen and (max-width: 900px) {
    padding-top: 20vh;
  }
`

const PageOutils = ({ data, path }) => {
  const prismic = data.prismicOutils.data
  const categories = [
    {
      title: prismic.first_category_title.text,
      tools: prismic.first_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.second_category_title.text,
      tools: prismic.second_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.third_category_title.text,
      tools: prismic.third_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.fourth_category_title.text,
      tools: prismic.fourth_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.fifth_category_title.text,
      tools: prismic.fifth_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.six_category_title.text,
      tools: prismic.six_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
    {
      title: prismic.seven_category_title.text,
      tools: prismic.seven_category.map(tool => {
        return {
          name: tool.tool_name.text,
          description: tool.tool_description.text,
        }
      }),
    },
  ]

  return (
    <Layout headerString={[""]} noMenu={true} hasScrolled={true}>
      <SEO
        title={prismic.page_title.text}
        description={prismic.page_description.text}
        image={prismic.og_image.url}
        location={path}
      />
      <ToolsMainContainer>
        <Title>{prismic.main_title.text}</Title>
        <StaticTitle>{prismic.main_subtitle.text}</StaticTitle>
        <ButtonContainer>
          <LinkBack className="click" to="/">
            retour au site
          </LinkBack>
        </ButtonContainer>
        <MobileContainer>
          <Row>
            <TableTitle>Category</TableTitle>
            <TableTitle>Tool</TableTitle>
            <TableTitle>Description</TableTitle>
          </Row>
          {categories.map(category => {
            return category.tools.map((tool, index) => {
              return index === 0 ? (
                <Row key={index}>
                  <Category>{category.title}</Category>
                  <Text>{tool.name}</Text>
                  <Text>{tool.description}</Text>
                </Row>
              ) : (
                <Row key={index}>
                  <p></p>
                  <Text>{tool.name}</Text>
                  <Text>{tool.description}</Text>
                </Row>
              )
            })
          })}
        </MobileContainer>
      </ToolsMainContainer>
    </Layout>
  )
}

export default PageOutils

export const query = graphql`
  query {
    prismicOutils {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        og_image {
          url
        }
        fifth_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        fifth_category_title {
          text
        }
        first_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        first_category_title {
          text
        }
        fourth_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        fourth_category_title {
          text
        }
        main_subtitle {
          text
        }
        main_title {
          text
        }
        second_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        second_category_title {
          text
        }
        seven_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        seven_category_title {
          text
        }
        six_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        six_category_title {
          text
        }
        third_category {
          tool_description {
            text
          }
          tool_name {
            text
          }
        }
        third_category_title {
          text
        }
      }
    }
  }
`
